<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>
    <div>
      <h6 class="text-primary font-weight-bold mb-2">Regionais</h6>
      <b-row>
        <b-col
          v-if="tr.nome != 'UAD'"
          class="mb-1"
          v-for="(tr, index) in modules.regionais"
          :key="index"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="addRegionalId(tr)"
            block
            size="sm"
          >
            {{ tr.nome }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-if="regional_id != null">
      <h6 class="text-primary font-weight-bold mb-2">Produtos</h6>
      <b-row>
        <b-col
          class="mb-1"
          lg="2"
          v-for="(tr, index) in modules.produtos"
          :key="index"
          v-show="tr.is_desempenho == 1"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="rankingShow(tr)"
            block
            size="sm"
          >
            {{ tr.nome }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <hr />
    <div>
      <div v-if="produto_id == null" class="text-center">
        <b-card>
          <h2>Selecione uma regional e um produto para mostrar o ranking!</h2>
          <p class="mt-2">
            <b-img
              fluid
              width="300"
              :src="require('@/assets/images/pages/investimentos.svg')"
            />
          </p>
        </b-card>
      </div>
      <div v-if="produto_id != null">
        <h4 class="text-center">
          Ranking {{ regionalNome }} - {{ produtoNome }}
        
        <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-dark"
            class="btn-icon rounded-circle"
            @click="showFilter(true)"
            v-show="filterShow == false"
          >
            <feather-icon icon="SlidersIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-dark"
            class="btn-icon rounded-circle"
            @click="showFilter(false)"
            v-show="filterShow == true"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </h4>
        <div v-show="filterShow == true">
          <b-card title="Filtros">
            <b-row>
              <b-col class="mb-1" lg="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  block
                  size="sm"
                  @click="fetchRanking('ASC')"
                >
                  Decrescente 
                </b-button>
              </b-col>
              <b-col class="mb-1" lg="2">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  block
                  size="sm"
                  @click="fetchRanking('DESC')"
                >
                  Crescente 
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <b-tabs content-class="mt-2" justified pills card>
          <b-tab v-if="ranking_valor.length > 0" title="Valor">
            <b-row>
              <b-col v-if="searchValor == null">
                <b-card>
                  <div class="text-center mt-2">
                    <b-row>
                      <b-col v-if="ranking_valor.length >= 2">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_bronze.svg')"
                            />
                          </p>
                          <h2>3º {{ ranking_valor[2].colaborador }}</h2>
                          <p>
                            <b>Valor Vendido:</b>
                            {{ ranking_valor[2].valor | dinheiro }}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_valor.length >= 0">
                        <div>
                          <p>
                            <b-img
                              fluid
                              width="120"
                              :src="require('@/assets/images/undraw/medal.svg')"
                            />
                          </p>
                          <h2>1º {{ ranking_valor[0].colaborador }}</h2>
                          <p>
                            <b>Valor Vendido:</b>
                            {{ ranking_valor[0].valor | dinheiro }}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_valor.length >= 1">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_prata.svg')"
                            />
                          </p>
                          <h2>2º {{ ranking_valor[1].colaborador }}</h2>
                          <p>
                            <b>Valor Vendido:</b>
                            {{ ranking_valor[1].valor | dinheiro }}
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                      <b-row v-show="ranking_valor.length >= 3">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>4º {{ ranking_valor[3].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Valor Vendido:</b>
                            {{ ranking_valor[3].valor | dinheiro }}
                      </b-col>
                    </b-row>
                    <b-row class="mt-2" v-if="ranking_valor.length > 4">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>5º {{ ranking_valor[4].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Valor Vendido:</b>
                            {{ ranking_valor[4].valor | dinheiro }}
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <b-col>
                <b-card no-body>
                  <div class="m-1">
                    <b-input-group>
                      <b-input-group-prepend is-text size="sm">
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input placeholder="Procurar" size="sm" v-model="searchValor" debounce="800" type="search" :disabled="isLoading"/>
                    </b-input-group>
                  </div>
                  <div class="ranking-style overflow-auto">
                    <b-table-simple striped responsive>
                      <b-thead>
                        <b-tr class="text-center">
                          <b-th>#</b-th>
                          <b-th>Nome</b-th>
                          <b-th>Valor</b-th>
                          <b-th>Ultima Atualização</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          :key="indextr"
                          v-for="(tr, indextr) in ranking_valor"
                        >
                          <b-td class="text-center">
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-gold"
                              v-if="tr.ranking == 1"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-silver"
                              v-if="tr.ranking == 2"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-bronze"
                              v-if="tr.ranking == 3"
                            />
                            <b v-if="tr.ranking > 3">{{tr.ranking}}</b>
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.colaborador }}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.valor | dinheiro }}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.data | dateTimeFormat }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab v-if="ranking_media.length > 0" title="Média">
            <b-row>
              <b-col v-if="searchMedia == null">
                <b-card>
                  <div class="text-center mt-2">
                    <b-row>
                      <b-col v-if="ranking_media.length >= 2">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_bronze.svg')"
                            />
                          </p>
                          <h3>3º {{ ranking_media[2].colaborador }}</h3>
                          <p>
                            <b>Média:</b>
                            {{ ranking_media[2].media | media }}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_media.length >= 0">
                        <div>
                          <p>
                            <b-img
                              fluid
                              width="120"
                              :src="require('@/assets/images/undraw/medal.svg')"
                            />
                          </p>
                          <h2>1º {{ ranking_media[0].colaborador }}</h2>
                          <p>
                            <b>Média:</b>
                            {{ ranking_media[0].media | media}}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_media.length >= 1">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_prata.svg')"
                            />
                          </p>
                          <h3>2º {{ ranking_media[1].colaborador }}</h3>
                          <p>
                            <b>Média:</b>
                            {{ ranking_media[1].media | media}}
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                    <b-row v-if="ranking_media.length >= 3">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>4º {{ ranking_media[3].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Média:</b>
                            {{ ranking_media[3].media | media }}
                      </b-col>
                    </b-row>
                      <b-row class="mt-2" v-if="ranking_media.length > 4">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>5º {{ ranking_media[4].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Média:</b>
                            {{ ranking_media[4].media | media }}
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <b-col>
                <b-card no-body>
                  <div class="m-1">
                    <b-input-group>
                      <b-input-group-prepend is-text size="sm">
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input placeholder="Procurar" size="sm" v-model="searchMedia" debounce="800" type="search" :disabled="isLoading"/>
                    </b-input-group>
                  </div>
                  <div class="ranking-style overflow-auto">
                    <b-table-simple striped responsive>
                      <b-thead>
                        <b-tr class="text-center">
                          <b-th>#</b-th>
                          <b-th>Nome</b-th>
                          <b-th>Média</b-th>
                          <b-th>Ultima Atualização</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          :key="indextr"
                          v-for="(tr, indextr) in ranking_media"
                        >
                          <b-td class="text-center">
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-gold"
                              v-if="tr.ranking == 1"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-silver"
                              v-if="tr.ranking == 2"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-bronze"
                              v-if="tr.ranking == 3"
                            />
                            <b v-if="tr.ranking > 3">{{tr.ranking}}</b>
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.colaborador }}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.media | media}}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.data | dateTimeFormat }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab v-if="ranking_quantidade.length > 0" title="Quantidade">
            <b-row>
              <b-col v-if="searchQuantidade == null">
                <b-card>
                  <div class="text-center mt-2">
                    <b-row>
                      <b-col v-if="ranking_quantidade.length >= 2">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_bronze.svg')"
                            />
                          </p>
                          <h3>3º {{ ranking_quantidade[2].colaborador }}</h3>
                          <p>
                            <b>Quantidade Vendida:</b>
                            {{ ranking_quantidade[2].quantidade }}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_quantidade.length >= 0">
                        <div>
                          <p>
                            <b-img
                              fluid
                              width="120"
                              :src="require('@/assets/images/undraw/medal.svg')"
                            />
                          </p>
                          <h2>1º {{ ranking_quantidade[0].colaborador }}</h2>
                          <p>
                            <b>Quantidade Vendida:</b>
                            {{ ranking_quantidade[0].quantidade }}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_quantidade.length >= 1">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_prata.svg')"
                            />
                          </p>
                          <h3>2º {{ ranking_quantidade[1].colaborador }}</h3>
                          <p>
                            <b>Quantidade Vendida:</b>
                            {{ ranking_quantidade[1].quantidade  }}
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                    <b-row v-if="ranking_quantidade.length >= 3">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>4º {{ ranking_quantidade[3].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Quantidade Vendida:</b>
                            {{ ranking_quantidade[3].quantidade }}
                      </b-col>
                    </b-row>
                    <b-row class="mt-2" v-if="ranking_quantidade.length > 4">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>5º {{ ranking_quantidade[4].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Quantidade Vendida:</b>
                            {{ ranking_quantidade[4].quantidade }}
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <b-col>
                <b-card no-body>
                  <div class="m-1">
                    <b-input-group>
                      <b-input-group-prepend is-text size="sm">
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input placeholder="Procurar" size="sm" v-model="searchQuantidade" debounce="800" type="search" :disabled="isLoading"/>
                    </b-input-group>
                  </div>
                  <div class="ranking-style overflow-auto">
                    <b-table-simple striped responsive>
                      <b-thead>
                        <b-tr class="text-center">
                          <b-th>#</b-th>
                          <b-th>Nome</b-th>
                          <b-th>Quantidade</b-th>
                          <b-th>Ultima Atualização</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          :key="indextr"
                          v-for="(tr, indextr) in ranking_quantidade"
                        >
                          <b-td class="text-center">
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-gold"
                              v-if="tr.ranking == 1"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-silver"
                              v-if="tr.ranking == 2"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-bronze"
                              v-if="tr.ranking == 3"
                            />
                            <b v-if="tr.ranking > 3">{{tr.ranking}}</b>
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.colaborador }}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.quantidade }}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.data | dateTimeFormat }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab v-if="ranking_indice.length > 0" title="Indice">
            <b-row>
              <b-col v-if="searchIndice == null">
                <b-card>
                  <div class="text-center mt-2">
                    <b-row>
                      <b-col v-if="ranking_indice.length >= 2">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_bronze.svg')"
                            />
                          </p>
                          <h3>3º {{ ranking_indice[2].colaborador }}</h3>
                          <p>
                            <b>Indice:</b>
                            {{ ranking_indice[2].indice}}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_indice.length >= 0">
                        <div>
                          <p>
                            <b-img
                              fluid
                              width="120"
                              :src="require('@/assets/images/undraw/medal.svg')"
                            />
                          </p>
                          <h2>1º {{ ranking_indice[0].colaborador }}</h2>
                          <p>
                            <b>Indice:</b>
                            {{ ranking_indice[0].indice}}
                          </p>
                        </div>
                      </b-col>
                      <b-col v-if="ranking_indice.length >= 1">
                        <div>
                          <p class="mt-5">
                            <b-img
                              fluid
                              width="80"
                              :src="require('@/assets/images/undraw/medal_prata.svg')"
                            />
                          </p>
                          <h3>2º {{ ranking_indice[1].colaborador }}</h3>
                          <p>
                            <b>Indice:</b>
                            {{ ranking_indice[1].indice}}
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                    <b-row v-if="ranking_indice.length >= 3">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>4º {{ ranking_indice[3].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Indice:</b>
                            {{ ranking_indice[3].indice}}
                      </b-col>
                    </b-row>
                    <b-row class="mt-2" v-if="ranking_indice.length > 4">
                      <b-col lg="2" class="text-right">
                        <b-img
                          fluid
                          width="50"
                          :src="require('@/assets/images/undraw/medal02.svg')"
                        />
                      </b-col>
                      <b-col lg="6" class="text-left mt-1">
                        <h4>5º {{ ranking_indice[4].colaborador }}</h4>
                      </b-col>
                      <b-col lg="4" class="text-left mt-1">
                        <b>Indice:</b>
                            {{ ranking_indice[4].indice}}
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
              <b-col>
                <b-card no-body>
                  <div class="m-1">
                    <b-input-group>
                      <b-input-group-prepend is-text size="sm">
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input placeholder="Procurar" size="sm" v-model="searchIndice" debounce="800" type="search" :disabled="isLoading"/>
                    </b-input-group>
                  </div>
                  <div class="ranking-style overflow-auto">
                    <b-table-simple striped responsive>
                      <b-thead>
                        <b-tr class="text-center">
                          <b-th>#</b-th>
                          <b-th>Nome</b-th>
                          <b-th>Indice</b-th>
                          <b-th>Ultima Atualização</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          :key="indextr"
                          v-for="(tr, indextr) in ranking_indice"
                        >
                          <b-td class="text-center">
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-gold"
                              v-if="tr.ranking == 1"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-silver"
                              v-if="tr.ranking == 2"
                            />
                            <feather-icon
                              icon="AwardIcon"
                              class="ranking-bronze"
                              v-if="tr.ranking == 3"
                            />
                            <b v-if="tr.ranking > 3">{{tr.ranking}}</b>
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.colaborador }}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.indice}}
                          </b-td>
                          <b-td class="text-center">
                            {{ tr.data | dateTimeFormat }}
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <div v-if="ranking_valor.length == 0 && ranking_media.length == 0 && ranking_indice.length == 0 && ranking_quantidade.length == 0">
            <b-card class="text-center">
              <h2>
                Encontramos nenhum dado correspondente em nosso sistema!
              </h2>
              <p>
                <small class="text-muted">Entre em contato com os administradores caso isso for um erro!</small>
              </p>
              <p class="mt-2">
                <b-img
                  fluid
                  width="300"
                  :src="require('@/assets/images/pages/empty.svg')"
                />
              </p>
            </b-card>
          </div>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: true,
      key: 1,
      produto_id: null,
      produtoNome: null,

      regional_id: null,
      regionalNome: null,

      ranking_valor: [],
      ranking_quantidade: [],
      ranking_media: [],
      ranking_indice: [],

      searchValor: null,
      searchQuantidade: null,
      searchIndice: null,
      searchMedia: null,
      filter: null,

      filterShow: false,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    rankingShow(tr) {
      this.produto_id = tr.id;
      this.produtoNome = tr.nome;
    },

    addRegionalId(tr) {
      this.produto_id = null;
      this.regional_id = tr.id;
      this.regionalNome = tr.nome;
    },

    showFilter(value) {
      this.filterShow = value;
    },

    /*
    / Listar e paginar
    */

    fetchRegionais(paginated = false, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "regionais",
          paginated: paginated,
          page: page,
          per_page: this.perPage,
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetchProdutos(paginated = false, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchModules", {
          pathAPI: "produtos",
          paginated: paginated,
          page: page,
          per_page: this.perPage,
        })
        .then((response) => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetchRanking(value) {
      this.ranking_valor = [];
      this.ranking_quantidade = [];
      this.isLoading = true;
      this.$store
        .dispatch("connectAPI/fetchRanking", {
          pathAPI: "regional",
          regional_id: this.regional_id,
          produto_id: this.produto_id,
          order: value,
        })
        .then((response) => {
          let valor = [];
          let quantidade = [];
          let media = [];
          let indice = [];
          this.ranking_valor = [];
          this.ranking_quantidade = [];
          this.ranking_media = [];
          this.ranking_indice = [];

          this.searchValor = null;
          this.searchQuantidade = null;
          this.searchMedia = null;
          this.searchIndice = null;
          
          if (response.ranking_valor.length > 0) {
            response.ranking_valor.forEach((value, index) => {
              valor.push(value);
            });

            this.ranking_valor = valor;
          }

          if (response.ranking_quantidade.length > 0) {
            response.ranking_quantidade.forEach((value, index) => {
              quantidade.push(value);
            });

            this.ranking_quantidade = quantidade;
          }

          if (response.ranking_media.length > 0) {
            response.ranking_media.forEach((value, index) => {
              media.push(value);
            });

            this.ranking_media = media;
          }

          if (response.ranking_indice.length > 0) {
            response.ranking_indice.forEach((value, index) => {
              indice.push(value);
            });
            this.ranking_indice = indice;
          }

          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filters(val, type){
      this.isLoading = true;
      if(val){
        if(type == 1){
          this.filter = this.ranking_valor.filter(function(valor) {
            return valor.colaborador.indexOf(val.toUpperCase()) >= 0
          });
          this.ranking_valor = this.filter
        }
        
        if(type == 2){
          this.filter = this.ranking_quantidade.filter(function(quantidade) {
            return quantidade.colaborador.indexOf(val.toUpperCase()) >= 0
          });
          this.ranking_quantidade = this.filter
        }

        if(type == 4){
          this.filter = this.ranking_media.filter(function(media) {
            return media.colaborador.indexOf(val.toUpperCase()) >= 0
          });
          this.ranking_media = this.filter
        }
        
        if(type == 3){
          this.filter = this.ranking_indice.filter(function(indice) {
            return indice.colaborador.indexOf(val.toUpperCase()) >= 0
          });
          this.ranking_indice = this.filter
        }

      }else{
        this.fetchRanking();
      }
      
      this.isLoading = false;
    }
  },
  watch: {
    regional_id() {
      this.fetchProdutos();
    },
    produto_id(value) {
      if (value != null) {
        this.fetchRanking();
      }
    },
    searchValor(val){
      this.filters(val, 1);
    },
    searchQuantidade(val){
      this.filters(val, 2);
    },
    searchIndice(val){
      this.filters(val, 3);
    },
    searchMedia(val){
      this.filters(val, 4);
    }
  },
  created() {
    this.fetchRegionais();
  },
};
</script>
<style lang="scss">
.ranking-gold {
  color: #ffd700;
}
.ranking-silver {
  color: #c0c0c0;
}
.ranking-bronze {
  color: #cd7f32;
}
.ranking-style {
  width: 100%;
  height: 475px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>
 
